import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Layout } from './pages/Layout';
import { Wedding } from './pages/Wedding';
import { Home } from './pages/Home';
import { Party } from './pages/Party';
import { About } from './pages/About';
import { NoPage } from './pages/NoPage';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="brollop" element={<Wedding />} />
            <Route path="bandet" element={<About />} />
            <Route path="fest" element={<Party />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
