import React, { useEffect, useRef, useState } from 'react';

export const Video = ({ song, title }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVideoLoaded(true);
    }, 1000);
  }, []);
  const videoRefs = useRef({});
  const [playingState, setPlayingState] = useState({});
  const handlePlayPause = (title) => {
    if (playingState[title]) {
      videoRefs.current[title].pause();
    } else {
      videoRefs.current[title].play();
    }

    setPlayingState((prevState) => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };

  return (
    <div className="flex justify-center flex-col items-center relative mb-8">
      <div className="nothing-you-could-do-regular h-10 normal-case text-contact tracking-tight">
        {title}
      </div>
      {!isVideoLoaded && (
        <div className="video-placeholder text-center lg:w-[466px] lg:h-[262px] w-[312px] h-[175px]  bg-black"></div>
      )}

      <video
        ref={(el) => (videoRefs.current[title] = el)}
        className={`w-full lg:w-1/2 ${isVideoLoaded ? 'block' : 'hidden'}`}
        onClick={() => handlePlayPause(title)}
        preload="auto"
        loading="lazy"
        controls={false}
      >
        <source src={song} type="video/mp4" />
      </video>
      {playingState[title] ? (
        <button
          className={`play-button absolute text-white m-auto left-0 right-0 top-0 bottom-[-40px] ${
            isVideoLoaded ? 'block' : 'hidden'
          }`}
          onClick={() => handlePlayPause(title)}
        >
          <i className="fa-solid fa-pause"></i>
        </button>
      ) : (
        <button
          className={`play-button absolute text-white m-auto left-0 right-0 top-0 bottom-[-40px] ${
            isVideoLoaded ? 'block' : 'hidden'
          }`}
          onClick={() => handlePlayPause(title)}
        >
          <i className="fa-solid fa-play"></i>
        </button>
      )}
    </div>
  );
};
