import React, { useState } from 'react';

export const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative">
      <div
        className={`hamburger-icon ${isOpen ? 'open' : ''}`}
        onClick={toggleMenu}
      >
        <div></div>
        <div></div>
      </div>

      <div
        className={`${
          isOpen
            ? 'fixed inset-0 bg-black z-40 flex items-center justify-center overlay'
            : 'hidden'
        }`}
      >
        <nav className="text-opacityBg shadow-lg flex w-full h-full">
          <ul className="space-y-4 text-center w-full content-center">
            <li>
              <a
                href="/"
                className="block p-4 text-orange hover:text-hoverOrange"
              >
                Hem
              </a>
            </li>
            <li className="mt-0.5">
              <a
                href="/bandet"
                className="block p-4  text-orange hover:text-hoverOrange"
              >
                Bandet
              </a>
            </li>
            <li className="mt-0.5">
              <a
                href="/brollop"
                className="block p-4 text-orange hover:text-hoverOrange"
              >
                Bröllop
              </a>
            </li>
            <li className="mt-0.5">
              <a
                href="/fest"
                className="block p-4 text-orange hover:text-hoverOrange"
              >
                Fest
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
