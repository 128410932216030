import React from 'react';
import logo from '../logos/fulllogo_transparent.png';
import '../index.css';
import { Helmet } from 'react-helmet-async';

export const Home = () => {
  return (
    <div className="p-4">
      <Helmet>
        <title>Hem</title>
      </Helmet>
      <img
        src={logo}
        alt="logo"
        className="h-30 md:h-48 lg:h-96 w-auto shine"
      />
      <div className="absolute inset-0 shine-and-grow-gradient"></div>
    </div>
  );
};
