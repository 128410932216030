import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { HamburgerMenu } from './HamburgerMenu';
import { Contact } from '../components/Contact';

export const Layout = () => {
  const location = useLocation();

  const isHomePage = location.pathname === '/';

  return (
    <div className="bg-background flex flex-col min-h-screen rem-font">
      <HamburgerMenu />
      <div className="flex-grow flex items-center justify-center">
        <Outlet />
      </div>
      {!isHomePage && <Contact />}
    </div>
  );
};
