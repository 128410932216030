import React from 'react';
import toLoveSomebody from '../assets/tolove.mp4';
import theJoke from '../assets/Thejoke.mp4';
import thePromise from '../assets/thepromise.mp4';
import everglow from '../assets/everglow.mp4';
import { Video } from '../components/video';
import { Helmet } from 'react-helmet-async';

export const Wedding = () => {
  const videoData = [
    { song: toLoveSomebody, title: 'toLoveSomebody' },
    { song: theJoke, title: 'theJoke' },
    { song: thePromise, title: 'thePromise' },
    { song: everglow, title: 'everglow' },
  ];
  return (
    <div className="my-36 w-4/5 gap-5 lg:gap-8 flex flex-wrap content-center">
      <Helmet>
        <title>bröllop</title>
      </Helmet>
      <div className="nothing-you-could-do-regular  text-center text-lg lg:text-2xl">
        <div className="uppercase text-title font-extrabold mb-4">
          Vill du göra ditt bröllop, vigsel eller dop till en oförglömlig dag?
        </div>
        <div className="normal-case text-contact tracking-tight">
          Med era utvalda låtar sätter vi pricken över i:et och ger er harmonisk
          sång, kombinerat med gitarr eller piano.
        </div>
      </div>
      <div className="CCC w-full">
        {videoData.map((video) => (
          <Video key={video.title} song={video.song} title={video.title} />
        ))}
      </div>
    </div>
  );
};
