import React from 'react';
import { Helmet } from 'react-helmet-async';

export const NoPage = () => {
  return (
    <>
      <Helmet>
        <title>Inget innehåll</title>
      </Helmet>
      <div className="lg:text-5xl text-2xl font-extrabold text-white">
        Oops! Här var det tomt!
      </div>
    </>
  );
};
