import React from 'react';
import smallLogo from '../logos/fulllogo_transparent_nobuffer.png';

export const Contact = () => {
  return (
    <div className="font-extrabold text-contact m-8 lg:m-16">
      <div className="text-5xl flex mb-12">
        <div className="text-2xl lg:text-5xl">
          <h2>kom i</h2>
          <h2>kontakt</h2>
        </div>
        <div className="pl-2 self-center">
          <img
            src={smallLogo}
            alt="smallLogo"
            className="h-8 lg:h-12 w-16 lg:w-24"
          ></img>
        </div>
      </div>

      <div className=" lg:flex">
        <div className="mr-32 lg:mr-48 ">
          <h3 className="mb-3">Resurgent sound</h3>
          <p className="text-sm font-light">Innehar F-skatt</p>
        </div>
        <div className="mt-6 lg:mt-0">
          <h3>
            Följ oss
            <span className="ml-8">
              <a href="https://www.instagram.com/resurgentsound?igsh=b2V1ZmRybXR6em5k">
                <i className="fa-brands fa-instagram text-title"></i>
              </a>
            </span>
          </h3>
        </div>
        <div className="mt-4 lg:mt-0 lg:ml-6">
          <a href="mailto:multiforetag@gmail.com">
            <span>Maila oss</span>{' '}
            <i className="fa-solid fa-envelope text-title ml-2 lg:ml-8"></i>
          </a>{' '}
        </div>
      </div>
    </div>
  );
};
