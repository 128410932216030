import React from 'react';
import smallLogo from '../logos/fulllogo_transparent_nobuffer.png';
import christoffer from '../logos/christoffer.jpeg';
import jennifer from '../logos/jennifer.jpeg';
import andreas from '../logos/andreas.jpeg';
import { Helmet } from 'react-helmet-async';

export const About = () => {
  return (
    <main className="w-100 text-contact text-sm lg:text-base mr-6 ml-6 mt-16 lg:mr-48 lg:ml-48 lg:mt-24">
      <Helmet>
        <title>Bandet</title>
      </Helmet>
      <div className="mb-24">
        <div className=" text-xl lg:text-5xl font-extrabold mb-2 text-title">
          Detta är vi
        </div>
        <div className="text-md lg:text-2xl font-extrabold mb-2 text-title">
          Resurgent sound
        </div>
        <div className="normal-case font-extralight text-xl text-lg:2xl text-center">
          Resurgent Sound är ett coverband, kända för det enkla och personliga.
          De trivs med att göra publiken delaktiga , sprida glädje och samtidigt
          förmedla känslor. Alla har olika bakgrund inom musiken men gemensamt
          är den breda erfarenheten, vilken mynnar ut i en ordinär dynamik dom
          emellan. De erbjuder ett stort utbud av genrer, lägger en extra touch
          och ibland dyker eget matrial upp på listan.
        </div>
      </div>
      <div className="flex mt-12 relative items-center">
        <div className="absolute top-72 lg:top-10 right-1 lg:left-1/4 flex text-xl lg:text-5xl font-extrabold">
          <span>Christoffer Malmgren</span>{' '}
          <img className="h-6 lg:h-12 w-16 lg:w-24" src={smallLogo} alt="" />
        </div>
        <div className="flex flex-col lg:flex-row ">
          <div className="flex justify-center w-1/1 lg:w-1/2">
            <img
              className="h-96 w-72 rounded overflow-hidden shadow-lg shadow-shad"
              src={christoffer}
              alt=""
            />
          </div>
          <div className=" w-1/1 lg:w-2/3 content-center pt-8 lg:pt-28 flex flex-col normal-case  text-xl text-lg:2xl font-extralight">
            <div className="flex">
              <i class="fa-solid fa-arrow-right pr-3 text-orange"></i>Jobbar som
              webbutvecklare men var innan det klädd i musikaliska skor. Han har
              både på gymnasienivå och yrkesförberedande väg valt att ägna sig
              åt musiken. Han har stor erfarenhet av att spela i band och att
              samhöra med likasinnande. Musiken finns i hans skånska blod och är
              en drivkraft.{' '}
            </div>
            <div className="pt-12 text-center">
              <span className="text-xl lg:text-3xl font-semibold	uppercase">
                Min roll i bandet:
              </span>{' '}
              Gitarrist, sångare och munspel
            </div>
          </div>
        </div>
      </div>
      <div className="flex mt-12 mb-12 lg:my-36 relative items-center">
        <div className="absolute top-72 lg:top-10 right-1 lg:right-1/4 flex text-xl lg:text-5xl font-extrabold ">
          <img className="h-6 lg:h-12 w-16 lg:w-24" src={smallLogo} alt="" />
          <span>jennifer andersson</span>
        </div>
        <div className="flex flex-col lg:flex-row lg:flex-row-reverse">
          <div className="flex justify-center w-1/1 lg:w-1/2">
            <img
              className="h-96 w-72 rounded overflow-hidden shadow-lg shadow-shad"
              src={jennifer}
              alt=""
            />
          </div>
          <div className=" w-1/1 lg:w-2/3 content-center pt-8 lg:pt-28 flex flex-col normal-case  text-xl text-lg:2xl font-extralight">
            <div className="flex">
              <i class="fa-solid fa-arrow-right pr-3 text-orange"></i>
              Uppväxt i en förrort norr om Stockholm och jobbar som naprapat.
              Hennes musikalitet har återspeglats i solouppdrag där hennes
              känsliga och vackra stämma har fyllt både kyrkor och konsertsalar.
              Fantastisk känsla för gehör och stämsång.
            </div>
            <div className="pt-12 text-center">
              <span className="text-xl lg:text-3xl font-semibold	uppercase">
                Min roll i bandet:
              </span>{' '}
              Frontsångerska, stämsång och slagverk
            </div>
          </div>
        </div>
      </div>
      <div className="flex mb-12 relative items-center">
        <div className="absolute top-72 lg:top-8 right-8 lg:right-1 lg:left-1/4 flex text-xl lg:text-5xl font-extrabold">
          <span>andreas kalodimos</span>{' '}
          <img className="h-6 lg:h-12 w-16 lg:w-24" src={smallLogo} alt="" />
        </div>
        <div className="flex flex-col lg:flex-row">
          <div className="flex justify-center w-1/1 lg:w-1/2">
            <img
              className="h-96 w-72 rounded overflow-hidden shadow-lg shadow-shad"
              src={andreas}
              alt=""
            />
          </div>
          <div className=" w-1/1 lg:w-2/3 content-center pt-8 lg:pt-28 flex flex-col normal-case  text-xl text-lg:2xl font-extralight">
            <div className="flex">
              <i class="fa-solid fa-arrow-right pr-3 text-orange"></i>
              Grekiskt påbrå och jobbar som musiklärare där han brinner för att
              se sina elever utvecklas. Han har öga för detaljer och ger bandet
              den extra touchen gällande kreativitet och ackompanjemang. Grym
              solo och kompgitarrist.
            </div>
            <div className="pt-12 text-center">
              <span className="text-xl lg:text-3xl font-semibold	uppercase">
                Min roll i bandet:
              </span>{' '}
              Solo/komp Gitarrist, stämsång
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
