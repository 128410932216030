import React from 'react';
import { Helmet } from 'react-helmet-async';
import alrightNow from '../assets/Alrightnow.mp4';
import comeTogether from '../assets/ComeTogether.mp4';
import theLook from '../assets/TheLook.mp4';
import sweetHome from '../assets/SweetHome.mp4';
import zombie from '../assets/Zombie.mp4';
import beast from '../assets/BeastOfBurden.mp4';
import { Video } from '../components/video';

export const Party = () => {
  const videoData = [
    { song: alrightNow, title: 'alrightNow' },
    { song: comeTogether, title: 'comeTogether' },
    { song: theLook, title: 'theLook' },
    { song: sweetHome, title: 'sweetHomeAlabama' },
    { song: zombie, title: 'Zombie' },
    { song: beast, title: 'beastOfBurden' },
  ];
  return (
    <div className="my-36 w-4/5 gap-5 lg:gap-8 flex flex-wrap content-center">
      <Helmet>
        <title>Fest</title>
      </Helmet>
      <div className="nothing-you-could-do-regular  text-center text-lg lg:text-2xl">
        <div className="uppercase text-title font-extrabold mb-4">
          Vill du göra din födelsedag, student eller kanske företagsfest till en
          oförglömlig kväll?
        </div>
        <div className="normal-case text-contact tracking-tight">
          Med allsidig repotoar kan vi presenteras som trio eller band och ge er
          en fartfullt kväll.
        </div>
      </div>
      <div className="w-full">
        {videoData.map((video) => (
          <Video key={video.title} song={video.song} title={video.title} />
        ))}
      </div>
    </div>
  );
};
